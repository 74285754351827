var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":require('../graphql/Batch.gql'),"variables":{ id: _vm.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v(" An error occured "),_c('pre',[_vm._v(_vm._s(error))])]):(data)?_c('div',{staticClass:"result apollo"},[_c('v-card',{staticClass:"mx-auto"},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h3"},[_vm._v(" "+_vm._s(data.batchById.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(new Date(data.batchById.startTime).toLocaleDateString())+" "+_vm._s(new Date(data.batchById.startTime).toLocaleTimeString())+" ")]),(
                data.batchById.measurements.some(
                  function (x) { return x.unit.abbreviation == 'AA'; }
                )
              )?_c('v-progress-linear',{attrs:{"value":data.batchById.measurements.find(
                  function (x) { return x.unit.abbreviation == 'AA'; }
                ).value,"color":"purple","dark":"","rounded":"","height":"25"}},[_c('strong',[_vm._v("AA "+_vm._s(Math.ceil( data.batchById.measurements.find( function (x) { return x.unit.abbreviation == "AA"; } ).value ))+"%")])]):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"to":'/brewery/' + data.batchById.brewery.id,"color":"primary","outlined":""}},[_vm._v(_vm._s(data.batchById.brewery.name)+" ")]),_c('v-btn',{attrs:{"to":'/vessel/' + data.batchById.vessel.id,"color":"primary","outlined":""}},[_vm._v(" TV "+_vm._s(data.batchById.vessel.totalVolume.value)+" L ")]),_c('v-btn',{attrs:{"to":'/device/' + data.batchById.device.id,"color":"purple","outlined":""}},[_vm._v(_vm._s(data.batchById.device.name)+" ")])],1)],1),_c('v-card',{staticClass:"mx-auto mt-10"},[_c('Chart',{attrs:{"telemetry":data.batchById.telemetry,"meta":data.batchById.meta}})],1),_c('v-card',{staticClass:"mx-auto mt-10"},[_c('Table',{attrs:{"url":"https://zymoscope-dev.netlify.app/api/notifications","params":{
            id: _vm.id,
          },"headers":[
            {
              text: 'User',
              align: 'start',
              value: 'email',
              width: 100,
            },
            {
              text: 'Gravity',
              align: 'start',
              value: 'conditions.gravity.value',
              width: 100,
            },
            {
              text: 'Pressure',
              align: 'start',
              value: 'conditions.pressure.value',
              width: 100,
            },
            {
              text: 'Fa',
              align: 'start',
              value: 'conditions.fermentationActivity.value',
              width: 100,
            },
            {
              text: 'Events',
              align: 'start',
              value: 'events.length',
              width: 100,
            } ]}})],1),_c('v-card',{staticClass:"mx-auto mt-10"},[_c('v-data-table',{attrs:{"disable-pagination":true,"hide-default-footer":true,"headers":[
            { text: 'Event', value: 'type' },
            { text: 'Timestamp', value: 'ts' },
            { text: 'User', value: 'user.email' },
            { text: 'Meta', value: 'meta' } ],"items":data.batchById.events},scopedSlots:_vm._u([{key:"item.meta",fn:function(ref){
          var item = ref.item;
return [_c('pre',[_vm._v(_vm._s(item.meta))])]}},{key:"item.ts",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.ts).fromNow())+" ")]}}],null,true)})],1)],1):_c('div',{staticClass:"no-result apollo"},[_vm._v("No result :( "+_vm._s(error))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }