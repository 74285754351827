

























































































































































import { Component, Vue } from "vue-property-decorator";
import Chart from "./batch-chart.vue";
import Table from "./data/table.vue";
import moment from "moment";

@Component({
  components: { Chart, Table },
  props: ["items"],
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  data: () => ({
    tab: "summary",
    search: "",
    moment,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Started", value: "started" },
      { text: "Ended", value: "ended" },
      { text: "Status", value: "brew_status" },
      { text: "SG", value: "SG" },
    ],
  }),
  methods: {
    getColor(status) {
      if (status == "FERMENTATION_ENDED") return "green";
      else if (status == "ERROR") return "red";
      else return "blue";
    },
  },
})
export default class Breweries extends Vue {}
