











import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/okta-login.vue"; // @ is an alias to /src
import Breweries from "@/components/breweries.vue"; // @ is an alias to /src
import Vessels from "@/components/vessels.vue"; // @ is an alias to /src
import Batch from "@/components/batch.vue"; // @ is an alias to /src

@Component({
  components: {
    Login,
    Breweries,
    Vessels,
    Batch,
  },
  computed: {},
  data: () => ({}),
  methods: {},
})
export default class Home extends Vue {}
